import Vue from 'vue'
import Vuex from 'vuex'

import isPrivateMode from '../../helpers/PrivateWindowMode'

// Modules
import mortgage from '../quiz-mortgage/store'

Vue.use(Vuex);

export const storeObject = {
  state: {
    loader: true,
    quiz: null,
    step: '',
    steps: [],
    form: {},
    error: null,
    success: null,
    result: null,
    isMobile: false,

    // Step of price
    loaderPrice: true,
    minRange: 1,
    maxRange: 100,

    // Complete
    loaderSend: false,
    successSend: null,

    quizStart: false,
    quizSent: false,
    quizDone: false,

    guestMode: false,
  },
  mutations: {
    setQuiz(state, data) {
      state.quiz = data;
      state.loader = false;
      state.steps = [...data.steps];

      state.quiz.steps.forEach(step => {
        if (!state.form[step.name]) {
          state.form[step.name] = step.type === 'checkbox' ? [] : null;
        }
      });

      state.step = state.steps[0].name;
    },

    setMobile(state, data) {
      state.isMobile = data;
    },

    setForm(state, form) {
      if (form && typeof form === 'object') {
        state.form = Array.isArray(form) ? {} : form;
      }
    },

    addCustomSteps(state, data) {
      state.steps = state.steps.concat(data);
    },

    appendCustomStepAfterKey(state, { value, stepNumber, remove = false }) {
      state.steps.splice(stepNumber, remove ? 1 : 0, value);

      console.log(state.steps);
    },

    setValue(state, { name, value }) {
      let form = {...state.form};
      form[name] = value;
      state.form = form;
    },

    next(state) {
      state.step = onChangeStep([...state.steps], state.step, state.form);
      state.quizStart = true;
      state.error = null;
      state.success = null;
    },

    prev(state) {
      state.step = onChangeStep([...state.steps].reverse(), state.step, state.form);
      state.error = null;
      state.success = null;
    },

    setError(state, message) {
      state.error = message;
    },

    setSuccess(state, message) {
      state.success = message;
    },

    clearMessage(state) {
      state.error = null;
      state.success = null;
      state.successSend = null;
    },

    setLoaderPrice(state, value) {
      state.loaderPrice = value;
    },

    setRangePrice(state, [min, max]) {
      state.minRange = min;
      state.maxRange = max;
    },

    setResult(state, value) {
      state.result = value;
    },

    setLoaderSend(state, value) {
      state.loaderSend = value;
    },

    setSuccessSend(state, value) {
      state.successSend = value;
      state.quizSent = true;
    },

    setHideForm(state, value) {
      state.successSend = value;
      state.quizSent = false;
      state.quizDone = true;
    },

    clearForm(state) {
      Object.keys(state.form).forEach(name => {
        if (typeof state.form[name] === 'object') {
          state.form[name] = [];
        } else {
          state.form[name] = null;
        }
      });
    },

    goHome(state) {
      state.step = state.steps[0].name;
      state.quizStart = false;
      state.quizSent = false;
      state.quizDone = false;
    },

    setGuestMode(state, value) {
      state.guestMode = value;
    },
  },
  actions: {
    async getQuiz({ commit }, { id, label, steps }) {
      const promise = new Promise(resolve => {
        resolve({ label, steps })
      });

      try {
        const data = await promise;
        commit('setQuiz', data);

        return data;
      } catch (e) {
        commit('setError', e.message);
      }
    },

    async getTotal({ commit, state }) {
      commit('setLoaderPrice', true);

      try {
        const { data } = await window.axios.post(`/quiz/api/total`, state.form);

        if (+data.flats === 0) {
          throw new Error(`
            В этом бюджете не найдено ни одной квартиры. 
            Попробуйте расширить бюджет, и мы обязательно что-нибудь подберем.
          `);
        }

        commit('setResult', data);
        commit('setLoaderPrice', false);
      } catch (e) {
        commit('setError', e.message);
        commit('setLoaderPrice', false);
      }
    },

    async getTotalWithNovos({ commit, state }) {
      commit('setLoaderPrice', true);

      try {
        const { data } = await window.axios.post(`/quiz/api/total-with-novos`, state.form);

        if (+data.counts.flats === 0) {
          throw new Error(`
            В этом бюджете не найдено ни одной квартиры. 
            Попробуйте расширить бюджет, и мы обязательно что-нибудь подберем.
          `);
        }

        commit('setResult', data);
        commit('setLoaderPrice', false);
      } catch (e) {
        commit('setError', e.message);
        commit('setLoaderPrice', false);
      }
    },

    async getRangePrices({ commit, state }, name) {
      commit('setLoaderPrice', true);

      const value = state.form[name] || [];

      try {
        const { data } = await window.axios.post(`/quiz/api/get-range-price`, state.form);

        commit('setRangePrice', data);

        if (!value.length) {
          commit('setValue', {
            value: data,
            name,
          });
        }

        return data;
      } catch (e) {
        commit('setError', `
          С выбранными параметрами не найдено ни одной квартиры. Попробуйте изменить 
          параметры, и мы обязательно что-нибудь подберем.
        `);
      }

      commit('setLoaderPrice', false);
    },

    async sendQuiz({ commit, state }, url = `/quiz/api/send`) {
      commit('setLoaderSend', true);

      try {
        let visitorId = null;


        if (typeof mcs.fp() != "undefined"){
          visitorId = mcs.fp();
        }

        const ya_uid = window['yaCounter' + document.querySelector('[ya-counter]').getAttribute('ya-counter')].getClientID();
        const { data } = await window.axios.post(url, Object.assign(state.form,{'visitorId': visitorId, 'firstref' : document.referrer, 'ya_uid' : ya_uid}));

        if (Comagic) {
          Comagic.addOfflineRequest(
            {
              name: state.form.username,
              phone: state.form.phone
            });
        }

        if (data.status === 'error') {
          commit('setError', data.message);
        } else if(data.status === 'hide_form'){
          commit('setHideForm', true);
        } else {
          commit('setSuccessSend', true);
        }
      } catch (e) {
        commit('setError', e.message);
      }

      commit('setLoaderSend', false);
    },

    async checkGuestMode({ commit }) {
      try {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
          throw new Error('Browser is not firefox');
        }

        let mode = await isPrivateMode();
        commit('setGuestMode', mode);
      } catch (e) {
        commit('setGuestMode', false);
      }
    }
  },
  getters: {
    getFields(state) {
      if (!state.quiz) {
        return [];
      }

      const step = state.quiz.steps.find((item, key) => key === state.step);

      return step ? step.fields : [];
    },

    getLabel(state) {
      return state.quiz ? state.quiz.label : null;
    },

    getForm(state) {
      if (!state.quiz) {
        return null;
      }

      const item = state.steps.find(({ name }) => name === state.step);

      return item || null;
    },

    isLast(state) {
      const steps = state.steps.concat();
      const item = steps[steps.length - 2];
      return item && item.name === state.step;
    },

    isFirst(state) {
      return state.step === state.steps[0].name;
    },

    isFinish(state) {
      return state.step === 'finish'
    },

    statSteps(state) {
      const stepNames = [];
      const form = state.steps.find(({ name }) => name === state.step);

      let level = null;

      state.steps.forEach(step => {
        let name = step.groupName || step.name;

        if (stepNames.indexOf(name) === -1) {
          stepNames.push(name);
        }
      });

      stepNames.pop();

      if (form) {
        stepNames.forEach((name, key) => {
          let nameForm = form.groupName || form.name;
          if (name === nameForm && !level) {
            level = key + 1;
          }
        });
      }

      return {
        level,
        levels: stepNames.length,
        names: stepNames,
        progress: Math.round((100 * (level || 1)) / stepNames.length)
      };
    },
  },

  modules: {
    mortgage,
  }
}

export default new Vuex.Store(storeObject);

const onChangeStep = (steps, thisStep, form) => {
  let step = null;

  steps.forEach(({ name }, key) => {
    if (name === thisStep && steps[key + 1] && !step) {
      let stepQuiz = steps[key + 1];

      if (validateChangeStep(stepQuiz, form)) {
        step = stepQuiz.name;
      } else {
        thisStep = stepQuiz.name;
      }
    }
  });

  return step;
};

const validateChangeStep = (stepQuiz, form) => {
  if (stepQuiz['and-if']) {
    let valid = true;
    let rule = null;

    Object.keys(stepQuiz['and-if']).forEach(name => {
      let value = form[name];

      eval(`rule = ${stepQuiz['and-if'][name]}`);

      if (valid && typeof(rule) === 'function' && !rule(value)) {
        valid = false;
      }
    });

    return valid;
  } else if (stepQuiz['or-if']) {
    let valid = false;
    let rule = null;

    Object.keys(stepQuiz['or-if']).forEach(name => {
      let value = form[name];

      eval(`rule = ${stepQuiz['or-if'][name]}`);

      if (!valid && typeof(rule) === 'function' && rule(value)) {
        valid = true;
      }
    });

    return valid;
  }

  return true;
};
